
html{box-sizing:border-box;}
*, *:before, *:after{box-sizing:inherit;}
ul{list-style-type:none;}
a{color:#039be5;text-decoration:none;-webkit-tap-highlight-color:transparent;}
ul{padding:0;margin:0}
ul li{list-style-type:none;}
a{text-decoration:none;}
html{line-height:1.5;font-family:"Roboto", sans-serif;font-weight:normal;color:rgba(0, 0, 0, 0.87);}
@media only screen and (min-width:0){html{font-size:14px;}
}
@media only screen and (min-width:992px){html{font-size:14.5px;}
}
@media only screen and (min-width:1200px){html{font-size:15px;}
}
h1, h2, h3, h4, h5, h6{font-weight:400;line-height:1.1;}
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a{font-weight:inherit;}
h1{font-size: 2rem;line-height:110%;margin:0;}
h2{font-size:3.56rem;line-height:110%;margin:1.78rem 0 1.424rem 0;}
h3{font-size:2.92rem;line-height:110%;margin:1.46rem 0 1.168rem 0;}
h4{font-size:2rem;line-height:110%;margin: 2rem;
  color: #606060;
  text-align: center;}
h5{font-size:1.64rem;line-height:110%;margin:0.82rem 0 0.656rem 0;}
h6{font-size:1rem;line-height:110%;margin:0.5rem 0 0.4rem 0;}
em{font-style:italic;}


body{
  background-color: #fafafa;
}


.App {
  text-align: center;
}

nav{
  height: 60px;
  background-color: #424242;
}

.menuButton{
  width:30px;
  margin: 15px;
}

.fixedMenuBtn{
  width:2rem;
  fill:#000;
  position: fixed;
  top:0.5rem;
  left:0.1rem;
  z-index: 10;
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
}

.invisible{
  opacity: 0;
  z-index: 0;
}

/* $primary-color: #282c34; */

.navHeader{
  background-color: #424242;
  /* background-color: $primary-color; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
}


.navProfilePhoto{
  margin: 10px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-image: url('./images/fotokv100.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.buttons{
  display: flex;
    /* width: 80%; */
    margin: 20px auto;
    flex-wrap: wrap;
    justify-content: center;
}
.button-about {

  display: flex;
    align-items: center;
    justify-content: center;
  min-width: 180px;
  max-width: 250px;
  flex-shrink: 0;
  flex: 1;
   height: 40px;
        margin: 10px;
        border:none;
        border-radius:2px;
        outline:none;
  background-color: #00bcd4;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  text-decoration: none;
  text-transform: uppercase;
    color: #fff;
    text-align: center;
    letter-spacing: .5px;
    /* position: relative; */
    cursor: pointer;
    /* overflow: hidden; */
    /* -webkit-user-select: none; */
    /* -moz-user-select: none; */
    /* -ms-user-select: none; */
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    vertical-align: middle;
    /* z-index: 1; */
    /* will-change: opacity, transform; */
    transition: all .3s ease-out;


}

.button-about:hover{
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #009cb0;
}

@media
  only screen and (-webkit-min-device-pixel-ratio: 2) ,
  only screen and (   min--moz-device-pixel-ratio: 2) ,
  only screen and (     -o-min-device-pixel-ratio: 2/1) ,
  only screen and (        min-device-pixel-ratio: 2) ,
  only screen and (                min-resolution: 192dpi),
  only screen and (                min-resolution: 2dppx)  { 
      
      /* Small screen, retina, stuff to override above media query */
   
      .navProfilePhoto{    background-image: url(./images/fotokv200.jpg);}
    
  }

.sideMenuTitle{
    margin: 0 0 10px 0;
    color: #ffffff;
    font-size: 1.2em;
    font-weight: 300;
}


.navLinks ul li
{
  display: flex;
  align-items: center;
}
.navLinks ul li a {
  color: #606060;
  padding: 10px 20px;
  /* transition: co; */
  transition: color .3s ease-in-out;
  font-size: 1.2rem;

}

.navLinks ul li a:hover{
  background: none;
    color: #00bcd4;
    text-decoration: none;
}

.topMenu{
  height: 100%;
  display: none;
  justify-content:space-between;
  align-items: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.topMenu li a {
    color: #ffffff;
    font-size: 15px;
    padding: 0;
    padding: 20px 20px;
    transition: color .3s ease-in-out;
}

.topMenu li a:hover {
  background-color: rgba(0, 0, 0, 0.2);
  color: #00dcf8;
  text-decoration: none;
}


.intro{
  background-color: #4f4f4f;
  position: relative;
  height: 200px;
  padding: 5px;
}

header h1, .intro h4 {
  text-align: center;
  color: #ffffff;
  /* text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.29); */

}
header {
  position: relative;
}
header h1{
    font-size: 2rem;
    margin: 1.2rem;
  }
 
  header h4{
     font-size: 1.2rem; 
     margin: 1rem;
  }


.introCircle{

  position: absolute;
    bottom: -85px;
    left: calc(50% - 85px);
    z-index: 2;
  background-color: #fff;
  border-radius: 50%;
  padding: 5px;
  width: 170px;
  height: 170px;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.2);
}

.introFoto{
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background-image: url('./images/fotokv200.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.about{
  display: flex;
  justify-content: center;
}

.card{
  transition: box-shadow .25s;
  border-radius: 2px;
  width: 90%;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  margin: 2rem;
  padding: 2rem;
}
@media only screen and (min-width:1024px){
  .card{
     width: 70%;
     max-width: 1280px;
    }
}

.aboutText{
  color:#606060;
  max-width: 30rem;
  margin: 0 auto;
}

.card .social{
  height: 60px;
}

@media(min-width :1024px){

  .aboutText{
    text-align: center;
  }

  .menuButton{
    display:none;
  }

  /* .sideMenu{
    display: none;
  } */
  .topMenu{
    display: flex;
  }
}



/* .fa-circle{
  font-size: 25px;
  color: #00bcd4;
  position: absolute;
  top: -10px;
  left: -48px;
  width: 25px;
  height: 25px;
  background-color: #00bcd4;
  border-radius: 50%;
} */

.icon-circle{
  width: 26px;
  height:26px;

  position: absolute;
  top: -13px;
  left: -52px;

}
.icon-circle svg {
  fill:#00bcd4;
}

.rights{
  text-align:center;
  font-size:0.8em;
}




