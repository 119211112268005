
.contacts-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    background-color: #424242;
    /* max-width:600px; */
    margin: 0 auto;

}


.contacts-item{
    /* width: 100%; */
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items:center;
    margin:1rem;
    /* max-width:500px; */
    font-size: 1.2rem;
    flex-direction: column;
    color: #fff;
}
.contacts-image{
    width:2rem;
    height: 2rem;
    margin:0.5rem;
}

.contacts-image svg{
    fill:#fff;
}

.contacts-item-text{
    margin:0.5rem;
}