.animated{-webkit-animation-duration:1.5s;animation-duration:1.5s;-webkit-animation-fill-mode:both;animation-fill-mode:both;}



@keyframes bounceInDown{from, 60%, 75%, 90%, to{-webkit-animation-timing-function:cubic-bezier(0.215, 0.610, 0.355, 1.000);animation-timing-function:cubic-bezier(0.215, 0.610, 0.355, 1.000);}
0%{opacity:0;-webkit-transform:translate3d(0, -3000px, 0);transform:translate3d(0, -3000px, 0);}
60%{opacity:1;-webkit-transform:translate3d(0, 25px, 0);transform:translate3d(0, 25px, 0);}
75%{-webkit-transform:translate3d(0, -10px, 0);transform:translate3d(0, -10px, 0);}
90%{-webkit-transform:translate3d(0, 5px, 0);transform:translate3d(0, 5px, 0);}
to{-webkit-transform:none;transform:none;}
}
.bounceInDown{-webkit-animation-name:bounceInDown;animation-name:bounceInDown;}


@keyframes zoomIn{from{opacity:0;-webkit-transform:scale3d(.3, .3, .3);transform:scale3d(.3, .3, .3);}
50%{opacity:1;}
}
.zoomIn{-webkit-animation-name:zoomIn;animation-name:zoomIn;}
@-webkit-keyframes zoomInDown{from{opacity:0;-webkit-transform:scale3d(.1, .1, .1) translate3d(0, -1000px, 0);transform:scale3d(.1, .1, .1) translate3d(0, -1000px, 0);-webkit-animation-timing-function:cubic-bezier(0.550, 0.055, 0.675, 0.190);animation-timing-function:cubic-bezier(0.550, 0.055, 0.675, 0.190);}
60%{opacity:1;-webkit-transform:scale3d(.475, .475, .475) translate3d(0, 60px, 0);transform:scale3d(.475, .475, .475) translate3d(0, 60px, 0);-webkit-animation-timing-function:cubic-bezier(0.175, 0.885, 0.320, 1);animation-timing-function:cubic-bezier(0.175, 0.885, 0.320, 1);}
}

@-webkit-keyframes fadeInDown{from{opacity:0;-webkit-transform:translate3d(0, -100%, 0);transform:translate3d(0, -100%, 0);}
to{opacity:1;-webkit-transform:none;transform:none;}
}
@keyframes fadeInDown{from{opacity:0;-webkit-transform:translate3d(0, -100%, 0);transform:translate3d(0, -100%, 0);}
to{opacity:1;-webkit-transform:none;transform:none;}
}
.fadeInDown{-webkit-animation-name:fadeInDown;animation-name:fadeInDown;}
@-webkit-keyframes fadeInDownBig{from{opacity:0;-webkit-transform:translate3d(0, -2000px, 0);transform:translate3d(0, -2000px, 0);}
to{opacity:1;-webkit-transform:none;transform:none;}
}
@keyframes fadeInDownBig{from{opacity:0;-webkit-transform:translate3d(0, -2000px, 0);transform:translate3d(0, -2000px, 0);}
to{opacity:1;-webkit-transform:none;transform:none;}
}

@-webkit-keyframes fadeInUp{from{opacity:0;-webkit-transform:translate3d(0, 100%, 0);transform:translate3d(0, 100%, 0);}
to{opacity:1;-webkit-transform:none;transform:none;}
}
@keyframes fadeInUp{from{opacity:0;-webkit-transform:translate3d(0, 100%, 0);transform:translate3d(0, 100%, 0);}
to{opacity:1;-webkit-transform:none;transform:none;}
}
.fadeInUp{-webkit-animation-name:fadeInUp;animation-name:fadeInUp;}
@-webkit-keyframes fadeInUpBig{from{opacity:0;-webkit-transform:translate3d(0, 2000px, 0);transform:translate3d(0, 2000px, 0);}
to{opacity:1;-webkit-transform:none;transform:none;}
}